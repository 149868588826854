import PropTypes from "prop-types"
import React from "react"
import { CookiesProvider } from "react-cookie"
import SEO from "./seo"

const Layout = ({ children }) => {
  return (
    <CookiesProvider>
      <div className="text-center bg-gray-900 text-gray-600">
        <SEO />
        <main>{children}</main>
      </div>
    </CookiesProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
